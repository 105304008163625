import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import Speakerlist from "../components/speakerlist"
import Program from "../components/program"
import image from "../../static/globart-plakat-rgb.jpg"

const Plakat = styled.img`
  position: absolute;
  width: 30%;
  max-width: 350px;
  left: 0;
  top: 3rem;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`

const Content = styled.div`
  max-width: 1200px;
  position: relative;
  margin: 3rem auto 0 auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
  }
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
`

const Parlament = styled.div`
  padding: 0 1rem;
  position: relative;
  width: 66%;
  margin-left: auto;
  h2:first-of-type {
    display: inline-block;
  }
  h2:last-of-type {
    float: right;
    display: inline-block;
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
    h2:first-of-type {
      display: inherit;
    }
    h2:last-of-type {
      float: none;
      display: inherit;
    }
  }
`

const Kopf = styled(Parlament)``
const Info = styled.div`
  padding: 0 1rem 0 1rem;
  align-self: flex-end;
  color: red;
  width: 33%;
  background: transparent;
  margin-bottom: 7.2em;

  h2 {
    margin-top: 48px;
    font-size: 2em;
    line-height: 1.45em;
    background: transparent;
  }
  a {
    color: white;
    background: red;
    padding: 1rem;
  }
  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-bottom: 0.62em;
    }
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
    transform: translateY(0);
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const Heading = styled.h1`
  padding: 1rem;
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const data = this.props.data.prismic.allEventseriess.edges[0].node
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }
    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY 2020 IM REAKTOR"
          description="Die GLOBART Academy Triade LEBEN-MACHT-SINN ist zurück! Die letzten Monate sind eine internationale Bestätigung: nun ist es Zeit über MACHT zu reden!"
          keywords={[
            "GLOBART Academy",
            "AKW Zwentendorf",
            "Kunst",
            "Katharina Rogenhofer",
            "Friedrich von Borries",
            "Michaela Krömer",
            "Jakob Brossmann",
            "Michael Kerbler",
            "Dorothee Hartinger",
            "Ramona Kordesch",
            "Tanz die Toleranz",
            "Michael Mautner",
            "Stephan A.Jansen",
            "Friedrich von Borries",
            "Kultur",
          ]}
        />
        <Content id="top">
          <Parlament id="akw">{RichText.render(data.body)}</Parlament>
          <Info>
            <h2>
              30. Oktober - 31.Oktober
              <br />
              AKW ZWENTENDORF
            </h2>
          </Info>
        </Content>

        <Program events={data.events} />
        <Speakerlist heading="Referentinnen" speakers={data.speakers} />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query AkwIndexQuery {
    prismic {
      allEventseriess(lang: "de-at", id: "Xv3p4RUAACkAeoM8") {
        edges {
          node {
            body
            speakers {
              speaker {
                ... on PRISMIC_Speaker {
                  title
                  description
                  thumbnail
                  quote
                  _meta {
                    uid
                    id
                  }
                }
              }
            }
            events {
              event {
                ... on PRISMIC_Event {
                  title
                  subtitle
                  end_time
                  start_time
                  formatdescription
                  description
                  allday
                  musik
                  thumbnail
                  moderation {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  speaker {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  musicians {
                    musiker {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  speakers {
                    additional_speakers {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        role
                        angefragt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  _meta {
                    uid
                    lang
                  }
                  location
                  parallel
                  eventlocation
                }
              }
            }
          }
        }
      }
    }
  }
`
